import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrImage from '@commons/wr-image';
import { StyledContainer } from '@styles/global-components/_containers';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '../../commons/wysiwyg-content';

export const StyledChapterTitle = styled(ModuleTitle)`
    font-weight: 400;
    font-style: italic;
    margin-bottom: 8px;
    text-align: center;
`

export const StyledTitle = styled(ModuleTitle)`
     text-align: center;
    font-weight: 800;
    margin: 0;
`

export const StyledTitlesSeperator = styled.hr`
    max-width: 300px;      
    width: 80%;
    background: #C4C4C4;
    margin: 20px auto 25px auto;

    ${theme.mediaQuerys.smUp} {
        width: 65%;
        max-width: 800px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin: 25px auto 35px auto;
    }
`

export const StyledImageAndTextContainer = styled(StyledContainer)`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    ${theme.mediaQuerys.smUp} {
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    width: 100%;
    max-width: 225px;
    margin: 0 auto 20px auto;

    ${theme.mediaQuerys.smUp} {
        width: 40%;
        max-width: none;
        margin: 0;
    }
`

export const StyledText = styled(WysiwygContent)`

    ${theme.mediaQuerys.smUp} {
        width: 60%;
        padding-right: 10%;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-right: 16%;
    }
`